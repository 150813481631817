import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'

import ProjectList from '../components/ProjectList'

export default function Home({ data, pageContext }) {
  const projects = data.projects.nodes
  return (
    <>
      <SEO title="All Projects" />
      <ProjectList projects={projects} />
    </>
  )
}

export const query = graphql`
  query {
    projects: allSanityProject(sort: { fields: date, order: DESC }) {
      nodes {
        name
        id
        slug {
          current
        }
        date
        cover {
          image {
            asset {
              fluid(maxWidth: 800, maxHeight: 600) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`
