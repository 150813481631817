import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import moment from 'moment'

const ProjectGridStyles = styled.div`
  display: grid;
  --col-width: 600px;
  grid-template-columns: repeat(auto-fill, minmax(var(--col-width), 1fr));
  @media (max-width: 1199px) {
    --col-width: 400px;
  }
  @media (max-width: 767px) {
    --col-width: 320px;
    gap: 2rem;
  }
`

const ProjectPanelStyles = styled.div`
  .panel-wrapper {
    position: relative;
    display: block;
    &:hover {
      .overlay {
        opacity: 0.6;
      }
    }
    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #000;
      opacity: 0.3;
      transition: opacity 0.3s ease;
    }
    .content-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    h2 {
      color: #fff;
      text-align: center;
      padding: 0 2rem;
      line-height: 4.6rem;
    }
    .date {
      position: absolute;
      right: 1rem;
      bottom: 1rem;
      color: var(--rust);
      margin: 0;
      padding: 1rem;
      font-family: var(--numbers-font-family);
      font-size: 3rem;
      line-height: normal;
    }
  }
`

function ProjectPanel({ project }) {
  return (
    <ProjectPanelStyles>
      <Link className="panel-wrapper" to={`/project/${project.slug.current}`}>
        {project.cover && (
          <Img fluid={project.cover.image.asset.fluid} alt={project.name} />
        )}
        <div className="overlay" />
        <div className="content-wrapper">
          <h2>{project.name}</h2>
          {/* {project.date && (
            <p className="date">
              {moment(project.date, 'YYYY-MM-DD').format('YYYY')}
            </p>
          )} */}
        </div>
      </Link>
    </ProjectPanelStyles>
  )
}

export default function ProjectList({ projects }) {
  return (
    <ProjectGridStyles>
      {projects.map(project => (
        <ProjectPanel key={project.id} project={project} />
      ))}
    </ProjectGridStyles>
  )
}
